import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import { create, test, enforce } from 'vest';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link, useLocation } from "react-router-dom";
import Button from 'components/Button';
import { useUserActions } from 'actions/user';
import { RegisterUser } from 'models/Register';

const validationSuite = create((data = {}) => {
  test('username', 'Username is required', () => {
    enforce(data.username).isNotEmpty();
    enforce(data.username).isString();
    //enforce(data.username).longerThanOrEquals(3);
  });

  test('surname', 'Surname is required', () => {
    enforce(data.surname).isNotEmpty();
    enforce(data.surname).isString();
  });

  test('firstname', 'Firstname is required', () => {
    enforce(data.firstname).isNotEmpty();
    enforce(data.firstname).isString();
  });

  test('email', 'Email is required', () => {
    enforce(data.email).isNotEmpty();
    enforce(data.email).isString();
  });

  test('email', 'Email is not valid', () => {
    enforce(data.email).condition(() => {
      if (data.email.match(emailRegex))
        return true;
      else {
        return false;
      }
    });
  });

  test('firstname', 'Firstname is not valid', () => {
    enforce(data.firstname).condition(() => {
      if (data.firstname.match(nameRegex))
        return true;
      else {
        return false;
      }
    });
  });

  test('surname', 'Surname is not valid', () => {
    enforce(data.surname).condition(() => {
      if (data.surname.match(nameRegex))
        return true;
      else {
        return false;
      }
    });
  });

  test('password', 'Password is required', () => {
    enforce(data.password).isNotEmpty();
  });

  test('password', 'The length must be at least 6 characters', () => {
    enforce(data.password).longerThanOrEquals(6);
  });

  test('username', 'The length must be at least 3 characters', () => {
    enforce(data.username).longerThanOrEquals(3);
  });
});

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const nameRegex = /^[a-zA-Z ,.'-]+$/;

type formFields = "username" | "surname" | "firstname" | "email" | "password" | "root" | `root.${string}` | "tenantId";

function Register() {
  const userActions = useUserActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [tenants, setTenants] = useState<Array<string>>(new Array<string>());
  const [selectedTenant, setSelectedTenant] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add('register-page');
    return () => {
      document.body.classList.remove('register-page');
    }
  }, [])

  useEffect(() => {
    if (location.state?.selectedTenant) {
      setSelectedTenant(location.state.selectedTenant);
    }
  }, [location]);

  useEffect(() => {
    const onGetTenants = async () => {
      try {
        const response = await userActions.getTenants();
        if (Array.isArray(response)) {
          setTenants(response);
          // Check if there is a selectedTenant passed via location.state
          const passedTenant = location.state?.selectedTenant;
          if (passedTenant && response.includes(passedTenant)) {
            setSelectedTenant(passedTenant);
          } else if (response.length > 0) {
            setSelectedTenant(response[0]);
          }
        } else {
          console.error('API did not return an array, failing silently.');
          setTenants([]);  // Set tenants to an empty array if not valid
        }
      } catch (err: any) {
        if (err?.response?.status === 401) {
          setGenericErrors(err.response.data);
        }
        if (err?.response?.status === 404) {
          setGenericErrors(err.response.data);
        }
        setTenants([]);
      }
    }
    onGetTenants();
  }, []);

  const onChangeTenant = (ten: string) => {
    setSelectedTenant(ten);
  };

  const toggleShowPassword = () => setShowPassword(prev => !prev);

  const { register, setError, handleSubmit, formState: { errors } } = useForm<RegisterUser>({
    resolver: vestResolver(validationSuite),
  });

  const onRegister = async (data: RegisterUser) => {
    try {
      await userActions.register(data);
      setGenericErrors(null);
    } catch (err: any) {
      // if(err?.response?.data) {
      //   Object.keys(err?.response?.data?.errors).map(key => {
      //     err?.response?.data?.errors[key].map((item: string) => {
      //       if(["password" , "username" , "firstname" , "surname" , "email" , "tenantId"].includes(key.toLowerCase())) {
      //         setError(key.toLowerCase() as formFields, { type: 'custom', message: item });
      //       }
      //     });
      //   });
      // }
      setGenericErrors(err?.response?.data);
    }
  }

  const onSubmit = handleSubmit((data) => onRegister({ ...data, tenantId: selectedTenant }), (error) => console.log('error', error));

  return (
    <div className="xa-auth-page-form-wrapper">
      <form>
        <div className="xa-auth-page-form-row">
          <div className="xa-form-field">
            <label htmlFor="groups" className="required">Tenants</label>
            <select name="groups" id="groups" onChange={(e) => onChangeTenant(e.target.value)} value={selectedTenant || ''}>
              {tenants?.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>))}
            </select>
          </div>
          <div className="xa-form-field">
            <label htmlFor="username" className="required">Username</label>
            <input type="text" id="username" autoComplete="username" required {...register("username")} className={errors?.username?.message && 'invalid'} />
            <div className="xa-auth-page-form-error">
              {errors?.username?.message && (errors.username.message)}
            </div>
          </div>
        </div>
        <div className="xa-auth-page-form-row">
          <div className="xa-form-field">
            <label htmlFor="firstname" className="required">Firstname</label>
            <input type="text" id="firstname" autoComplete="firstname" required {...register("firstname")} className={errors?.firstname?.message && 'invalid'} />
            <div className="xa-auth-page-form-error">
              {errors?.firstname?.message && (errors.firstname.message)}
            </div>
          </div>
          <div className="xa-form-field">
            <label htmlFor="surname" className="required">Surname</label>
            <input type="text" id="surname" autoComplete="surname" required {...register("surname")} className={errors?.surname?.message && 'invalid'} />
            <div className="xa-auth-page-form-error">
              {errors?.surname?.message && (errors.surname.message)}
            </div>
          </div>
        </div>
        <div className="xa-auth-page-form-row">
          <div className="xa-form-field">
            <label htmlFor="email" className="required">Email</label>
            <input type="text" id="email" autoComplete="email" required {...register("email")} className={errors?.email?.message && 'invalid'} />
            <div className="xa-auth-page-form-error">
              {errors?.email?.message && (errors.email.message)}
            </div>
          </div>
          <div className="xa-form-field">
            <label htmlFor="password" className="required">Password</label>
            <div className="relative">
              <input type={showPassword ? "text" : "password"} id="password" autoComplete="password" required {...register("password")} className={errors?.password?.message && 'invalid'} />
              <div className="xa-auth-page-form-password-view" onClick={toggleShowPassword}>
                {showPassword ? (<FiEye />) : (<FiEyeOff />)}
              </div>
            </div>
            <div className="xa-auth-page-form-error">
              {errors?.password?.message && (errors.password.message)}
            </div>
          </div>
        </div>
        <div className="xa-auth-page-form-errors">
          {genericErrors && (genericErrors)}
        </div>
        <div className="xa-auth-page-form-link">
          <Link to="/login" state={{ selectedTenant }}>Already have an account?</Link>
        </div>
        <Button onClick={onSubmit} color="primary" full isButton>
          Register
        </Button>
      </form>
    </div>
  );
}

export default Register;
