// @ts-expect-error
import MapView from "@arcgis/core/views/MapView";
// @ts-expect-error
import ArcGISMap from "@arcgis/core/Map";
// @ts-expect-error
import esriConfig from "@arcgis/core/config";
// @ts-expect-error
import Extent from "@arcgis/core/geometry/Extent";
// @ts-expect-error
import ScaleBar from "@arcgis/core/widgets/ScaleBar";
// import OpenStreetMapLayer from "@arcgis/core/layers/OpenStreetMapLayer";
// @ts-expect-error
import WebTileLayer from "@arcgis/core/layers/WebTileLayer";
// @ts-expect-error
import Zoom from "@arcgis/core/widgets/Zoom";
import { getHeader } from 'utils/request';
import { getUrls } from "./getUrls";
const BASE_API_URL = `${process.env.REACT_APP_API_URL}/api`;

export const MAX_ZOOM = 22;

export function initializeMap(ref: HTMLDivElement, setBaseLayer: React.Dispatch<React.SetStateAction<any>>) {

  esriConfig.assetsPath = "./assets";
  esriConfig.request.interceptors = [];
  esriConfig.request.interceptors.push({
    // set the `urls` property to the URL of the FeatureLayer so that this
    // interceptor only applies to requests made to the FeatureLayer URL
    urls: getUrls(),
    headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
  });
  var ext = new Extent({
    xmin: -197253.726104,
    ymin: 6903313.7840020005,
    xmax: -196852.49644,
    ymax: 6903741.440501,
    spatialReference: {
      wkid: 3857
    }
  });

  // const osmLayer = new OpenStreetMapLayer({
  //   visible: true,
  //   title: "Open Street Map",
  //   // tileServers=[""],
  // });

  var wmsLayer = new WebTileLayer({
    // spatialReference: spatRef,
    name: "StadiaMaps",
    title: "StadiaMaps",
    urlTemplate: `https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=${process.env.REACT_APP_STADIA_MAPS_API_KEY}`,
    visible: true
  });

  const map = new ArcGISMap({
    layers: [wmsLayer],
  });

  const view = new MapView({
    container: ref,
    map: map,
    center: ext.center, // Longitude, latitude
    zoom: 5,
    extent: ext,
    slider: false,
    navigation: {
      mouseWheelZoomEnabled: true,
    },
    popupEnabled: false,
  });

  view.ui.components = [];
  view.popupEnabled = false;

  let zoom = new Zoom({
    view: view
  });

  view.ui.add(zoom, {
    position: "bottom-right"
  });

  let scaleBar = new ScaleBar({
    view: view,
    unit: "dual",
  });

  view.ui.add(scaleBar, {
    position: "bottom-right"
  });

  //Constrain the max and min zoom levels
  view.constraints = {
    minZoom: 2,
    maxZoom: MAX_ZOOM
  };

  setBaseLayer(wmsLayer);

  return view;
}
