import Tenants from 'components/AdministrationTools//Tenants/Tenants';
import NavBar from 'components/NavBar';
import UserContainer from 'components/UserContainer';
import { useAppSelector } from 'hooks/appSelector';
import React, { useState, useEffect } from 'react'
import './style.scss'
import NewUsers from 'components/AdministrationTools/NewUsers/NewUsers';
import RegisteredUsers from 'components/AdministrationTools/RegisteredUsers';
import withAdminCheck from 'HOC/withAdminCheck';
import Charting from 'containers/Charting';
import { useNavigate } from 'react-router-dom';

type AdministrationToolsProps = {};

const AdministrationTools: React.FC<AdministrationToolsProps> = () => {

  const user = useAppSelector((state) => state.user);
  const [primaryIsExpanded, setPrimaryIsExpanded] = React.useState<boolean | null>(null);
  const [modalState, setModalState] = useState<Record<string, boolean>>({
    'Major asset group overview': false,
    'Basemaps': true,
    'Attributes': false,
    'Legend': true,
  });
  const [isPageRendered, setIsPageRendered] = useState(false); // State to track page rendering

  const navigate = useNavigate();


  const showModal = (title: string) => {
    setModalState((prevState) => ({ ...prevState, [title]: true }))
  };

  const onCloseModal = (title: string) => {setModalState((prevState) => ({ ...prevState, [title]: false }));}

   useEffect(() => {
    // If navbar is opened, close it on first page load
    setPrimaryIsExpanded(false);
  }, []);


  return (
    <div className="xa-admin-page">
       {isPageRendered && modalState['Major asset group overview'] && (
        <Charting
          isOpen={modalState['Major asset group overview']}
          closeModal={() => onCloseModal('Major asset group overview')}
        />
      )}
      <NavBar
        displayModal={showModal}
        isExpanded={primaryIsExpanded}
        setIsExpanded={setPrimaryIsExpanded}
      />
      <div className='xa-admin-content'>
        <Tenants />
        <NewUsers />
        <RegisteredUsers />
      </div>
      <UserContainer
        email={user.email}
        firstname={user.firstname}
        surname={user.surname}
        navbarExpanded={primaryIsExpanded}
      />
    </div>
  )
}

export default withAdminCheck(AdministrationTools);
