import { Categories, LegendResponse } from "@actions/legend";

interface MyObject {
  [key: string]: any;
}

export const getLegendStructure = (legendResponse: LegendResponse) => {
  return legendResponse?.layerCategories?.reduce((accumulator: any[], category: Categories) => {

    if (!category.layers) {
      return accumulator;
    }

    if (accumulator.find(accItem => accItem.title === category.name) == null) {
      accumulator.push({
        title: category.name,
        key: category.name === "Network" ? "network-layer" : category.name,
        category: category.name,
        checkable: category.name === "Network",
        selectable: category.name === "Network",
        isLeaf: category.name === "Network",
        children: [],
      });
    }

    category?.layers?.forEach((layer, layerIndex) => {
      if (!layer?.subCategories && category.name === 'Network') {
          let isFound = accumulator.find(layerCategory => layerCategory.title === category.name);
          if (isFound) {
            isFound.endPoint = layer.endPoint;
            isFound.isChecked = layer.enabled;
          }
        }


        if (!layer?.subCategories && category.name !== 'Network') {
          accumulator.find(layerCategory => layerCategory.title === category.name).children.push({
            title: layer.name,
            key: `${category.name}+${layer.key ?? `${layer.name}`}+${layer.year}+${Math.floor(Math.random() * 10000)}`,
            category: layer.name,
            endPoint: layer.endPoint,
            isLeaf: true,
            isChecked: layer.enabled,
            children: [],
            parameterId: layer?.key
          });
        }

        const addedSubcategories = new Set();

        // Example of layer?.subCategories?.length > 1:
        // Surveys -> Pavements -> CNS -> 2020 (Pavements and CNS are the 2 subcategories).
        // Candidate Schemes -> Other -> Footway -> 1 -> 2022 (Other, Footway and 1 are the 3 subcategories)
        if (layer?.subCategories?.length > 1) {
          // This assumes there are a maximum of 3 subcategories.
          const addedSecondSubcategories = new Set();
          const addedThirdSubcategories = new Set();
          const addedFourthSubcategories = new Set();

          const variables: MyObject = {};
          for (let i = 0; i < layer?.subCategories?.length; i++) {
            variables['subcategory_' + i] = layer?.subCategories[i];
          }

          // Handle first subcategory
          if (!addedSubcategories.has(variables.subcategory_0)) {
            addedSubcategories.add(variables.subcategory_0);
            const subcategoryExists = accumulator.find(layerCategory => layerCategory.title === category.name).children.some((child: {
              title: any;
            }) => child.title === variables.subcategory_0);

            if (!subcategoryExists) {
              accumulator.find(layerCategory => layerCategory.title === category.name).children.push({
                title: variables.subcategory_0,
                key: `${category.name}+${layer.key ?? `${layer.name}`}+${layer.year}+${variables.subcategory_0}+${Math.floor(Math.random() * 10000)}`,
                category: layer.name,
                isLeaf: false,
                checkable: false,
                selectable: false,
                children: [],
                parameterId: layer?.key
              });
            }

            if (!addedSecondSubcategories.has(variables.subcategory_1)) {
              addedSecondSubcategories.add(variables.subcategory_1);
              const secondSubcategoryExists = accumulator?.find(layerCategory => layerCategory?.title === category?.name)?.children?.find((subcategory: {
                  title: any;
                }) => subcategory?.title === variables?.subcategory_0)?.children?.find((child: {
                  title: any;
                }) => child.title === variables.subcategory_1)
              ;
              if (!secondSubcategoryExists) {
                accumulator?.find(layerCategory => layerCategory?.title === category?.name)?.children?.find((subcategory: {
                  title: any;
                }) => subcategory?.title === variables?.subcategory_0)?.children.push({
                  title: variables.subcategory_1,
                  key: `${category.name}+${layer.key ?? `${layer.name}`}+${layer.year}+${variables.subcategory_1}+${Math.floor(Math.random() * 10000)}`,
                  category: layer.name,
                  isLeaf: false,
                  checkable: false,
                  selectable: false,
                  children: [],
                  parameterId: layer?.key
                });
              }
            }

            if (!variables.subcategory_2) {
              accumulator?.find(layerCategory => layerCategory?.title === category?.name)?.children?.find((subcategory: {
                title: any;
              }) => subcategory?.title === variables?.subcategory_0)?.children.find((ch: {
                title: any
              }) => ch.title === variables.subcategory_1).children.push({
                title: layer.name,
                key: `${category.name}+${layer.key ?? `${layer.name}`}+${layer.year}++${variables.subcategory_1}+${Math.floor(Math.random() * 10000)}`,
                endPoint: layer.endPoint,
                category: layer.name,
                isChecked: layer.enabled,
                isLeaf: true,
                checkable: true,
                selectable: true,
                parameterId: layer?.key
              })
            }

            // Handle third subcategory
            if (variables.subcategory_2) {
              if (!addedThirdSubcategories.has(variables.subcategory_2)) {
                addedThirdSubcategories.add(variables.subcategory_2);
                const thirdSubcategoryExists = accumulator?.find(layerCategory => layerCategory?.title === category?.name)?.children?.find((subcategory: {
                  title: any;
                }) => subcategory?.title === variables?.subcategory_0)?.children?.find((child: {
                  title: any;
                }) => child.title === variables.subcategory_1)?.children?.find(((child: {
                  title: any;
                }) => child.title === variables.subcategory_2));

                if (!thirdSubcategoryExists) {
                  accumulator?.find(layerCategory => layerCategory?.title === category?.name)?.children?.find((subcategory: {
                    title: any;
                  }) => subcategory?.title === variables?.subcategory_0)?.children.find((child: {
                    title: any;
                  }) => child.title === variables?.subcategory_1).children.push({
                    title: variables.subcategory_2,
                    key: `${category.name}+${layer.key ?? `${layer.name}`}+${layer.year}+${variables.subcategory_2}+${Math.floor(Math.random() * 10000)}`,
                    category: layer.name,
                    isLeaf: false,
                    checkable: false,
                    selectable: false,
                    children: [],
                  });
                }
              }
              if (!variables.subcategory_3) {
                accumulator?.find(layerCategory => layerCategory?.title === category?.name)?.children?.find((subcategory: {
                  title: any;
                }) => subcategory?.title === variables?.subcategory_0)?.children.find((ch: {
                  title: any
                }) => ch.title === variables.subcategory_1).children.find((ch: {
                  title: any
                }) => ch.title === variables.subcategory_2).children.push({
                  title: layer.name,
                  key: `${category.name}+${layer.key ?? `${layer.name}`}+${layer.year}++${variables.subcategory_2}+${Math.floor(Math.random() * 10000)}`,
                  endPoint: layer.endPoint,
                  category: layer.name,
                  isChecked: layer.enabled,
                  isLeaf: true,
                  checkable: true,
                  selectable: true,
                })
              }
            }

            // Handle fourth subcategory
            if (variables.subcategory_3) {
              if (!addedFourthSubcategories.has(variables.subcategory_3)) {
                addedFourthSubcategories.add(variables.subcategory_3);
                const fourthSubcategoryExists = accumulator?.find(layerCategory => layerCategory?.title === category?.name)?.children?.find((subcategory: {
                  title: any;
                }) => subcategory?.title === variables?.subcategory_0)?.children?.find((child: {
                  title: any;
                }) => child.title === variables.subcategory_1)?.children?.find(((child: {
                  title: any;
                }) => child.title === variables.subcategory_2)).children?.find(((child: {
                  title: any;
                }) => child.title === variables.subcategory_3));

                if (!fourthSubcategoryExists) {
                  accumulator?.find(layerCategory => layerCategory?.title === category?.name)?.children?.find((subcategory: {
                    title: any;
                  }) => subcategory?.title === variables?.subcategory_0)?.children.find((child: {
                    title: any;
                  }) => child.title === variables?.subcategory_1).children.find((child: {
                    title: any;
                  }) => child.title === variables?.subcategory_2).children.push({
                    title: variables.subcategory_3,
                    key: `${category.name}+${layer.key ?? `${layer.name}`}+${layer.year}+${variables.subcategory_3}+${Math.floor(Math.random() * 10000)}`,
                    category: layer.name,
                    isLeaf: false,
                    checkable: false,
                    selectable: false,
                    children: [],
                    parameterId: layer?.key
                  });
                }

                accumulator?.find(layerCategory => layerCategory?.title === category?.name)?.children?.find((subcategory: {
                  title: any;
                }) => subcategory?.title === variables?.subcategory_0)?.children.find((ch: {
                  title: any
                }) => ch.title === variables.subcategory_1).children.find((ch: {
                  title: any
                }) => ch.title === variables.subcategory_2).children.find((ch: {
                  title: any
                }) => ch.title === variables.subcategory_3).children.push({
                  title: layer.name,
                  key: `${category.name}+${layer.key ?? `${layer.name}`}+${layer.year}++${variables.subcategory_3}+${Math.floor(Math.random() * 10000)}`,
                  category: layer.name,
                  endPoint: layer.endPoint,
                  isChecked: layer.enabled,
                  isLeaf: true,
                  checkable: true,
                  selectable: true,
                  parameterId: layer?.key
                })
              }
            }
          }
        } else {
          layer?.subCategories?.forEach((subcategory, subCategoryIndex) => {
            if (!addedSubcategories.has(subcategory)) {
              addedSubcategories.add(subcategory);
              const subcategoryExists = accumulator.find(layerCategory => layerCategory.title === category.name).children.some((child: {
                title: any;
              }) => child.title === subcategory);

              if (!subcategoryExists) {
                accumulator.find(layerCategory => layerCategory.title === category.name).children.push({
                  title: subcategory,
                  key: `${category.name}+${layer.key ?? `${layer.name}`}+${layer.year}+${subCategoryIndex}+${subcategory}`,
                  category: layer.name,
                  isLeaf: false,
                  checkable: false,
                  selectable: false,
                  children: [],
                  parameterId: layer?.key
                });
              }
            }

            accumulator.find(layerCategory => layerCategory.title === category.name).children.find((child: {
              title: any;
            }) => child.title === subcategory).children.push({
              title: layer.name,
              key: `${category.name}+${layer.key ?? `${layer.name}`}+${layer.year}+${subCategoryIndex + 1}+${subcategory}`,
              category: layer.name,
              endPoint: layer.endPoint,
              isChecked: layer.enabled,
              isLeaf: true,
              children: [],
              parameterId: layer?.key
            })
          });
        }
      }
    )
    return accumulator;
  }, []);
}
