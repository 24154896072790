import React, { useEffect, useState } from 'react'
import Tree from 'rc-tree';
import { assetActions, AssetAttributesInfo, AssetElementsInfo, EyeViDto } from 'actions/asset';

type AssetHubLegendProps = {
  data: any;
  modalDetails: any;
  setModalDetails: React.Dispatch<React.SetStateAction<any>>;
  infoTrigger: boolean;
  setInfoTrigger: React.Dispatch<React.SetStateAction<boolean>>
  setSpanInfoError: React.Dispatch<React.SetStateAction<boolean>>
  setWasAssetHubRefreshLoading: React.Dispatch<React.SetStateAction<boolean>>
  wasAssetHubRefreshedClicked: boolean,
  wasAssetHubRefreshLoading: boolean,
};

const AssetHubLegend: React.FC<AssetHubLegendProps> = ({data, modalDetails, setModalDetails, infoTrigger, setInfoTrigger, setSpanInfoError, wasAssetHubRefreshedClicked,wasAssetHubRefreshLoading, setWasAssetHubRefreshLoading }) => {
  const TREE_DATA = [
    {
      key: 'parent-asset',
      title: 'Bridge Asset( No. 620B)',
      children: [],
    },
  ];

  const [attributes, setAttributes] = useState<AssetAttributesInfo[]>([]);
  const [elements, setElements] = useState<AssetElementsInfo[]>([]);
  const [showAttributes, setShowAttributes] = useState<boolean>(false);
  const [showElements, setShowElements] = useState<boolean>(false);
  const [treeData, setTreeData] = useState(TREE_DATA)



  useEffect(() => {
    if (!showAttributes || data?.category !== 'asset' || !modalDetails?.id) {
      return;
    }

    const fetchAssetAttributes = async () => {
      try {
        const result = await assetActions().getAssetAttributes(modalDetails.id, wasAssetHubRefreshedClicked);
        if (wasAssetHubRefreshedClicked) setWasAssetHubRefreshLoading(false);
        setAttributes(result);

        const attributes = {
          key: '0-0-0',
          title: 'Attributes',
          children: result.map((item: any) => ({
            key: item.name,
            title: item.name,
            [item.name]: item.value,
            parent: 'Attributes'
          })),
        }

        const localTree = [...treeData] as any;
        if(localTree?.[0]?.children){
          localTree[0].children[0] = attributes;
        }

        setTreeData(localTree);
      } catch (error) {
        console.error('Failed to fetch asset attributes:', error);
      }
    };

    fetchAssetAttributes();
  }, [showAttributes, wasAssetHubRefreshLoading]);

  function generateRandomKey() {
    return Math.random().toString(36).substring(2, 15);
  }

  useEffect(() => {
    if (showElements && data?.category === 'asset' && modalDetails?.id) {
      assetActions().getAssetElements(modalDetails.id, wasAssetHubRefreshedClicked).then((result) => {
        const groupedData = result.reduce((acc: any, item) => {
          const existingEntry = acc.find((entry: any) => entry.title === `Span No ${item.spanNumber}`);

          const modifiedItem = {
            ...item,
            key: generateRandomKey(),
            title: item.name,
            parent: "Spans",

          };

          if (existingEntry) {
            existingEntry.children.push(modifiedItem);
          } else {
            acc.push({
              title: `Span No ${item.spanNumber}`,
              key: generateRandomKey(),
              children: [modifiedItem],
              number: item.spanNumber,
              isSpanNode: true
            });
          }

          return acc;
        }, []);

        const spans = {
          key: generateRandomKey(),
          title: 'Spans',
          children: groupedData
        }

        const localTree = [...treeData] as any;
        if(localTree?.[0]?.children){
          localTree[0].children[1] = spans;
        }

        setTreeData(localTree);
        setElements(result);
      });
    }
  }, [showElements, wasAssetHubRefreshLoading]);

  useEffect(() => {
    
   if(modalDetails) {
    assetActions().getAssetDetails(data.id, wasAssetHubRefreshedClicked).then((result: any) => {
      setShowAttributes(result.attributes || false)
      setShowElements(result.elements || false);
      const localTree = [...treeData];
      localTree[0].title = `Asset No. ${result.number}`
      setTreeData(localTree)
    });

    };
  }, [modalDetails, wasAssetHubRefreshLoading])


  const handleNodeClick = (selectedKeys: any, e: any) => {
    setSpanInfoError(false)
    if(!e.selectedNodes?.[0]?.children && e.selectedNodes?.[0]?.parent){
      if(e.selectedNodes[0].parent === 'Attributes'){
        setModalDetails({...e.selectedNodes[0],[selectedKeys]: e.selectedNodes[0]?.[selectedKeys?.[0]]})
      }else{
        assetActions().getElementAttributes(e.selectedNodes[0].assetId, e.selectedNodes[0].spanNumber, e.selectedNodes[0].id, wasAssetHubRefreshedClicked).then((result: any)=> {
        const combinedObject = {} as any;
        result.forEach((item: any) => {
          if(item.name && item.value){
            combinedObject[item.name] = (item.value)
          }
        })
          setModalDetails(combinedObject);
        }).catch(() => {
          setSpanInfoError(true)
          setModalDetails(null)
        })
      }
    }else if(e.selectedNodes?.[0]?.title === 'Attributes') {
        assetActions().getAssetAttributes(data.id, wasAssetHubRefreshedClicked).then((result: any) => {
          setModalDetails(result);
        }).catch((err) => console.log(err))
    }else if(e.selectedNodes?.[0]?.isSpanNode){
      assetActions().getSpanInfo(data.id, e.selectedNodes[0]?.number).then((result: any)=> {
        setModalDetails(result);
      })
    }else{
      setInfoTrigger(!infoTrigger)
    }

  };

  return <div className='xa_asset_hub--legend_container'>
    <Tree
      showLine
      selectable
      defaultExpandParent={true}
      autoExpandParent={true}
      treeData={treeData}
      showIcon={false}
      onSelect={handleNodeClick}
      defaultExpandedKeys={["parent-asset"]}
    />
  </div>
}

export default AssetHubLegend;
